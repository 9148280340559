import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { About } from "../page/About";
import { Ambiental } from "../page/Ambiental";
import { Contact } from "../page/Contact";
import { Home } from "../page/Home";
import { Planos } from "../page/planos";

export function AppRouter() {
    return (
        <>
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/ambiental" element={<Ambiental />} />
                    <Route path="/planos" element={<Planos />} />
                </Routes>
                <Footer/>
            </BrowserRouter>
        </>
    )
}