import styled from "styled-components";

export const Container = styled.div`
display: flex;
align-items: center;
margin-left: 2rem;
color: var(--blue300);
svg{
    margin-right: .5rem;
}
p{
    font-weight: bold;
}
a{
    display: flex;
align-items: center;
color: var(--blue300);
}
`