import styled from "styled-components";

export interface Props {
    number: any;
}

export const Container = styled.div<Props>`
    @media(min-width: 900px){
        display: none;
    }
   width: 95%;
   display: flex;
   margin-top: 9.5rem;
   justify-content: ${(p) => p.number % 2 === 0 ? "end" : 'start'};
    div{
    width: 250px;
    display: flex;
    flex-direction: column;
    align-self: start;
    justify-content: center;
    color: #000;
    text-align: ${(p) => p.number % 2 === 0 ? "end" : 'start'};
    line-height: 1.7rem;
    span{
        color: var(--orange);
        font-size: 2.5rem;
    }
    p{
        font-size: 1.2rem;
    }
    h3{
        font-size: 1.4rem;
        margin: 1rem 0;
    }
    
    
}
    @media(max-width:820px){
        margin-top: 8rem;
    }
    @media(max-width:780px){
        margin-top: 5rem;
    }
    @media(max-width:700px){
        margin-top: 4rem;
    }

    
    @media(max-width:600px){
        margin-top: 0.5rem;

        div{
            width:200px;
            span{
                color: var(--orange);
                font-size: 2rem;
            }
            p{
                font-size: 1rem;
            }
            h3{
                font-size: 1.2rem;
                margin: .5rem 0;
            }
        }
    }
    @media(max-width:550px){
        margin-top: 3rem;
       
        div{
            width:200px;
            line-height: 1.5rem;
            span{
                color: var(--orange);
                font-size: 2rem;
            }
            p{
                font-size: 1rem;
            }
            h3{
                font-size: 1.2rem;
                margin: .5rem 0;
            }
        }
    }
  
    @media(max-width:510px){
        margin-top: 1rem;
       
        div{
            width:150px;
            line-height: 1.5rem;
            span{
                
                font-size: 1.5rem;
            }
            p{
                font-size: .8rem;
            }
            h3{
                font-size: 1rem;
                margin: .5rem 0;
            }
        }
    }
    @media(max-width:480px){
        margin-top: -2rem;
    }
 
    @media(max-width:414px){
        margin-top: -2.2rem;
       
       
    }
    @media(max-width:390px){
        margin-top: -2.5rem;
       
       
    }
    @media(max-width:393px){
        margin-top: -2.7rem;
       
       
    }
    @media(max-width:380px){
        margin-top: 0rem;
       
        div{
            width:120px;
            line-height: 1rem;
            span{
                
                font-size: 1.5rem;
            }
            p{
                font-size: .5rem;
            }
            h3{
                font-size: .8rem;
                margin: .5rem 0;
            }
        }
    }
    @media(max-width:380px){
        margin-top: -2rem;
       
        div{
            width:120px;
            line-height: 1rem;
            span{
                
                font-size: 1.5rem;
            }
            p{
                font-size: .5rem;
            }
            h3{
                font-size: .8rem;
                margin: .5rem 0;
            }
        }
    }


`