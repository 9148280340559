import styled from "styled-components";

export const Container = styled.header`
width:100%;
height:auto;
margin:0 auto;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
background-color: var(--footer);
color:var(--shape);
header{
    height:100%;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: start;
    div{
        width:28%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;

        hr{
            width: 20%;
            margin:.5rem 0;
        }
       
        nav{
            ul{
                list-style: none;
                li{
                    margin: 1rem 0;
                    a{
                    color:var(--shape);
                    text-decoration: none;
                    transition: all 0.4s;

                    &:hover{
                        text-decoration: underline;
                    }

                }

                }
            }
        }

        span{
            margin: .5rem 0;

            a{
                color:var(--shape);
               
                text-decoration: none;


            }
        }
    }
}
footer{
    background-color: var(--footer1);
    width:100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width:580px){
    header{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div{
            align-items: center;
            justify-content: center;
            width: 95%;
            margin-bottom: 3rem;

            nav{
                ul{
                    text-align: center;
                }
            }

            &:last-child{
                align-items: center;
            justify-content: center;
                width:300px
            }
        }

        
    }
}
`

