import { Container } from "./styled";
import { Slider } from './slider/index'
import { useRef, useState } from "react";
import { WindowSize } from "../../config/WindowSize";
import { Info } from "./info";
import InputMask from 'react-input-mask';
import { GrFormClose } from "react-icons/gr";
import emailjs from '@emailjs/browser';
import { Link } from "react-router-dom";

export function Home() {

    const [tel, setTel] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [problem, setProblem] = useState('')
    const [type, setType] = useState('')
    const { width } = WindowSize()
    const [show, setShow] = useState(false)
    const form = useRef();

    function sendEmail(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        emailjs.sendForm('service_30ma3xp', 'template_4jtccky', form.current as any, '55dEuD9d1ATPxSP0z')
            .then((result: any) => {
                console.log(result.text);
            }, (error: any) => {
                console.log(error.text);
            });
        if (name !== "") {
            window.open(`https://api.whatsapp.com/send?phone=559294845086&text=Nome%3A%20${name.replaceAll(" ", "%20").toLowerCase()}%0ATelefone%3A%20%20${tel}%0AEndereco%3A%20%20${address.replaceAll(" ", "%20")}%0AEmail%3A%20${email}%0ADescreva%20Seu%20Problema%3A%20%20${problem.replaceAll(" ", "%20")}%0ATipo%20De%20Problema%3A%20%20${type}`, '_blank');
        }
        e.currentTarget.reset()
    };

    return (
        <Container>
            <div className="section1">
                {width > 600 ? (<video src="banner.mp4" title="Elefar" autoPlay={true} loop={true}/>) : (<video src="banner.mp4" title="Elefar" autoPlay={true} loop={true}/>)}
                <div className="text">
                    <p>
                        Conheça nosso plano de manutenção de <strong>saúde ambiental</strong>, para garantir a segurança do seu
                        ambiente o ano inteiro!
                    </p>
                    <span>A partir de 69,90 R$</span>

                    <button
                        onClick={() => { setShow(true) }}
                    >Contratar Agora</button>
                </div>
                <form ref={form as any} className={show ? "" : 'none'} onSubmit={sendEmail}>
                    <header

                    >
                        <p>Solicite um orçamento </p>
                        {width < 900 && (
                            <button
                                onClick={() => { setShow(false) }}
                            >
                                <GrFormClose />

                            </button>
                        )}
                    </header>
                    <main>
                        <section>
                            <label htmlFor="">NOME:</label>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} name='name' required />
                        </section>
                        <section>
                            <label htmlFor="">TELEFONE:</label>
                            <InputMask name="phone" id="phone" mask="(99)99999-9999" required value={tel} onChange={(e) => { setTel(e.target.value) }} />
                        </section>
                        <section>
                            <label htmlFor="">ENDEREÇO:</label>
                            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} name='address' required />
                        </section>
                        <section>
                            <label htmlFor="">EMAIL:</label>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name='email' required />
                        </section>
                        <section>
                            <label htmlFor="">TIPO DE ESTABELECIMENTO:</label>
                            <select name="" id="" value={type} onChange={(e) => setType(e.target.value)}>
                                <option value="Casa">Casa</option>
                                <option value="Apartamento">Apartamento</option>
                                <option value="Comercio">Comercio</option>
                                <option value="Indústria">Indústria</option>
                                <option value="Clinica de saúde">Clínica de saúde</option>
                            </select>
                        </section>
                        <section>
                            <label htmlFor="">DESCREVA SEU PROBLEMA:</label>
                            <textarea value={problem} onChange={(e) => setProblem(e.target.value)} name='email' required />
                        </section>
                      
                    </main>
                    <footer >



                        <button type="submit">
                            Solicite Agora
                        </button>


                    </footer>
                </form>
            </div>
            <div className="section2">
                <div className="title">
                    <h2>Somos especializados em </h2>
                    <h2>SAÚDE AMBIENTAL</h2>
                    <div />
                </div>

                <div className="text">
                    <div className="img">
                        <img src="especialista.jpg" alt="Especialista" />
                    </div>
                    <p>Segundo a Organização Mundial da Saúde (OMS), Saúde Ambiental são todos aqueles aspectos da saúde humana, incluindo a qualidade de vida, que estão determinados por fatores físicos, químicos, biológicos, sociais e psicológicos no meio ambiente. Também se refere à teoria e prática de prevenir ou controlar tais fatores de risco que, potencialmente, possam prejudicar a saúde de gerações atuais e futuras (OMS, 1993).</p>
                </div>
            </div>
            <div className="section3">
                {width > 900 ? (<img src="section3.png" alt="section3" />) : <img src="ativo4.png" alt="section3" />}
                <Info
                    number='01'
                    title='Desifecção de ambientes'
                    text="Desinfecção de ambientes é um processo de higienização que tem como objetivo eliminar micro-organismos de objetos e superfícies."
                />
                <Info
                    number='02'
                    title='Qualidade do ar'
                    text="Em locais fechados, onde a renovação do ar é menor, há maior concentração de poluentes e agentes nocivos à saúde no ambiente, o que pode ser potecializado em caso de uso de aparelhos de ar-condicionado sujos."
                />

                <Info
                    number='03'
                    title='Qualidade da água'
                    text="A portabilidade da água é fundamental para a manutenção de saúde, tornando a manutenção de reservatórios de água indispensável para a manutenção da qualidade de água consumida."
                />
                <Info
                    number='04'
                    title='Ambiente livre de pragas'
                    text="Ratos, baratas, mosquitos e demais pragas, são vetores de transmissão de doenças. Sendo imprescindível o controle de pragas para manter um ambiente saudável."
                />
                


            </div>
            <div className="section4">

                <h2>Conheça nossas soluções</h2>

                <div className="slide">
                    <Slider
                        img="mosca.jpeg"
                        text="Mosquitos"
                        icon="mosca_icon.png"
                    />
                    <Slider
                        img="aranha.jpeg"
                        text="Controle de Aranha"
                        icon="aranha_icon.png"
                    />
                    <Slider
                        img="escorpiao.jpeg"
                        text="Controle de Escorpiões"
                        icon="escorpiao_icon.png"
                    />
                    <Slider
                        img="barata.jpeg"
                        text="Controle de Baratas"
                        icon="barata_icon.png"
                    />
                    <Slider
                        img="rato.jpeg"
                        text="Controle de Roedores"
                        icon="rato_icon.png"
                    />
                    <Slider
                        img="cupim.jpeg"
                        text="Controle de Cupins"
                        icon="cupim_icon.png"
                    />
                    <Slider
                        img="./pombo.jpeg"
                        text="Controle de Pombos"
                        icon="./pombo_icon.png"
                    />
                    <Slider
                        img="./caixa.jpg"
                        text="Limpeza de caixa D'agua"
                        icon="./caixa_icon.png"
                    />
                </div>
            </div>

            <div className="section5">
                <div>
                    <header>
                        <img src="especialista2.jpg" alt="especialista2.jpg" />
                    </header>
                    <main>
                        <h2>ELES ESTÃO INVADINDO SUA CASA
                        </h2>
                        <header>
                            <img src="especialista2.jpg" alt="especialista2.jpg" />
                        </header>
                        <p>
                            Nosso propósito é garantir um ambiente saudável, seguro e tranquilo, através de um serviço rápido, profissional e de excelência
                        </p>

                    </main>
                </div>
            </div>

            <div className="section6">
                <div className="img">
                    <img src="./especialista3.jpg" alt="especialista3" />
                    <img src="./especialista600x400.jpg" alt="especialista3" />
                </div>

                <div className="text">
                    <h2>
                        Investir em <span>saúde ambiental</span> é investir em <span>qualidade de vida!</span>
                    </h2>

                    <Link to="/planos">SOLICITE UM ORAÇAMENTO</Link>
                </div>

            </div>
            <div className="section7">
                <h2>Quem conheçe nossos serviços</h2>


                <div>
                    
                    <span><img src="./empresas/1.jpg" alt="empresa1" /></span>
                    <span><img src="./empresas/2.png" alt="empresa2" /></span>
                    <span><img src="./empresas/3.png" alt="empresa3" /></span>
                    <span><img src="./empresas/4.png" alt="empresa4" /></span>
                    <span><img src="./empresas/5.png" alt="empresa5" /></span>
                    <span><img src="./empresas/7.jpg" alt="empresa7" /></span>
         
                    
                    
                    
                   
             

                </div>

            </div>

        </Container>
    )
}