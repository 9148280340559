/* eslint-disable react/jsx-no-comment-textnodes */
import { Icons } from "./icons";
import { Container, Content, Main } from "./styled";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoLogoWhatsapp } from "react-icons/io";
import { AiFillFacebook } from "react-icons/ai";
import { GrInstagram, GrMail } from "react-icons/gr";
import { Link } from "react-router-dom";
import { useState } from "react";

export function Header() {

    const [show, setShow] = useState(false)

    return (
        <Container>
            <Main show={show}>
                <div className="icon" onClick={()=>setShow(true)}>
                    <GiHamburgerMenu />
                </div>
                <Link to='/'><img src="./logo.png" alt="logo" /></Link>
                <main>

                    <Icons
                        icon={<BsFillTelephoneFill />}
                        title="(92) 99511-7987 / (92) 99484-5086"
                        mail={false}
                    />


                    <Icons
                        icon={<GrMail />}
                        title="elefar@elefar.com.br"
                        mail={true}
                    />

                </main>
                <div className="client">
                    <a href="https://phcfocosistema.com.br/elefar/minhaconta/?fbclid=IwAR0kGCvwexDGCB4wCLZQv49Jf0AQQg2sr7xUZPM6nH0RO-OzNK-SXTushms" target="_blank" className="link" rel="noreferrer">Área do cliente</a>
                    <div>
                        <a href="https://wa.me/message/DEQUR2ATV4BGO1" target="_blank" rel="noreferrer"><IoLogoWhatsapp className="whatsapp" /></a>
                        <a href="https://www.facebook.com/Elefar.Ambiental" target="_blank" rel="noreferrer"><AiFillFacebook className="facebook" /></a>
                        <a href="https://www.instagram.com/elefar.ambiental/?hl=pt-br"  target="_blank" rel="noreferrer"><GrInstagram className="instagram" /></a>
                    </div>
                </div>
            </Main>

            <Content show={show}>
                <div onClick={()=>setShow(false)}>
                    <MdOutlineClose />
                </div>
                <ul>
                    <li onClick={()=>setShow(false)}><Link to='/'>Home</Link></li>
                    <li onClick={()=>setShow(false)}>< Link to='about'>Sobre nós</Link></li>
                    <li onClick={()=>setShow(false)}><Link to='ambiental'>Saúde Ambiental</Link></li>
                    <li onClick={()=>setShow(false)}><Link to='planos'>Nossos Planos</Link></li>
                    <li onClick={()=>setShow(false)}><Link to='contact'>Contato</Link></li>
                </ul>
            </Content>
        </Container>
    )
}