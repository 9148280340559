import {Container} from './style'

interface Slide {
    img:string;
    text:string;
    icon:string;
}

export function Slider(
    {img, text,icon}:Slide
){
    return(
        <Container>
            <img src={img} alt=""  className='slide'/>

            <div className='before'>
                <img src={icon} alt="" />
                <p>{text}</p>
                <hr />
            </div>

        </Container>
    )
}