import { Link } from "react-router-dom";
import { Container } from "./styled";



export function Ambiental() {
    return (
        <Container>
            <div className="banner">
                <img src="banner2.jpg" alt="alt" />

                <div>

                    <nav>
                        <Link to="/">Home</Link>
                        <p> {'>'} </p>
                        <span>Saúde Ambiental</span>
                    </nav>

                    <h2>Saúde Ambiental</h2>

                </div>

            </div>
            <div className="section1">
                <div>
                    <main>
                        <p>
                            A saúde ambiental é o ramo da
                            saúde pública preocupado com
                            todos os aspectos do ambiente
                            natural e construído que afetam a
                            saúde humana.Para controlar
                            efetivamente os fatores que
                            podem afetar a saúde. A saúde
                            ambiental concentra-se nos
                            ambientes naturais e construídos
                            para o benefício da saúde humana
                        </p>
                        <header>
                            <img src="familia.jpg" alt="familia.jpg" />
                        </header>


                    </main>
                    <header>
                        <img src="familia.jpg" alt="familia.jpg" />
                    </header>

                </div>

            </div>

            <div className="section2">
                <img src="oms.png" alt="oms.png" />
                <div>
                    <h2>Definições da Organização Mundial da Saúde (OMS)</h2>

                    <p>A saúde ambiental foi definida em um documento de 1989 pela Organização Mundial da Saúde (OMS) como: Aqueles aspectos da saúde humana e da doença que são determinados por fatores no meio ambiente.[3] e controlando fatores no ambiente que podem potencialmente afetar a saúde.[4] Também é referido como a teoria e a prática de acessar e controlar fatores no ambiente que podem afetar potencialmente a saúde.</p>
                    <p>
                        Segundo a OMS,a saúde ambiental "Inclui tanto os efeitos patológicos diretos de produtos químicos,
                        radiação e alguns agentes biológicos,quanto os efeitos(frequentemente indiretos) sobre a saúde eo
                        bem-estar do amplo ambiente físico, psicológico, social e cultural, que inclui habitação,desenvolvimento
                        urbano,uso do solo e transporte.

                    </p>
                    <p>
                        A saúde ambiental aborda todos os fatores físicos, químicos e biológicos externos a uma pessoa e todos
                        os fatores relacionados que afetam os comportamentos. Abrange a avaliação e o controle dos fatores
                        ambientais que podem potencialmente afetar a saúde. É direcionado para prevenir doenças e criar
                        ambientes de apoio à saúde. Esta definição exclui o comportamento não relacionado ao ambiente, bem
                        como o comportamento relacionado ao ambiente social e cultural, bem como a genética.
                    </p>
                </div>
            </div>

            <div className="section3">
                <div>

                    <main>
                        <h2>Desinfecção de Ambientes
                        </h2>
                        <header>
                            <img src="especialista5.png" alt="especialista5.png" />
                        </header>
                        <p>
                            Quando falamos de saúde ambiental não podemos esquecer que um de seus objetivos é promover um ambiente não apenas propício ao ser humano de utilizar, mas de favorecer a saúde e bem-estar de todos e a primeira barreira para manter um ambiente saudável vem da manutenção da limpeza básica do local, onde limpar, espanar e remover sujidades aparentes é essencial para a saúde humana e animal.
                        </p>

                    </main>
                    <header>
                        <img src="especialista5.png" alt="especialista5.png" />
                    </header>
                </div>
                <p>Com a realização frequente dessas é possível reduzir uma série de fatores que contribuem para a instalação de animais nocivos a saúde como ratos, baratas e formigas, por exemplo, no entanto há microrganismos que permanecem no ambiente mesmo após uma limpeza básica sendo necessário realizar uma desinfecção no local para eliminá-los.</p>
            </div>
            <div className="section4">
                <div className="img">
                    <img src="./saude-ambiental-4.jpg" alt="saude-ambiental-4" />
                    <img src="./saude-ambiental-4.jpg" alt="saude-ambiental-4" />
                </div>

                <div className="text">
                    <h2>
                        <span>“</span> A desinfecção nada mais é
                        que a utilização de produtos
                        com a capacidade de eliminar
                        esses microrganismos que ainda
                        ficaram no ambiente <span>”</span>
                    </h2>
                </div>

            </div>
            <div className="section5">
                <section>
                    <img src="bug.png" alt="bug" />

                    <div>
                        <p>São produtos mais potentes com princípios
                            ativos que, geralmente ,destroem a parece
                            celular deles como: hipoclorito de sódio, cloro,
                            álcool,quaternário de amônia. Esses produtos
                            têm a capacidade de <strong>eliminar até 99,9% dos
                                microrganismos</strong> através do contato.
                        </p>
                        <p>
                            Am anipulação desses desinfetantes
                            profissionais deve ser realizada por <strong>profissionais
                                devidamente treinado</strong> se utilizando equipamentos
                            de proteção individual adequado.
                        </p>

                    </div>
                </section>
            </div>
            <div className="section6">
                <div>

                    <main>
                        <h2>Qualidade do Ar
                        </h2>
                        <header>
                            <img src="saude-ambiental-5.jpg" alt="saude-ambiental-5.jpg" />
                        </header>
                        <p>
                            Segundo a Organização Mundial de Saúde (OMS) um ser humano adulto vive de 80 a 90% do seu tempo em ambientes fechados. A Organização considera, inclusive, que as concentrações de poluentes em ambientes fechados, dependendo das condições, podem ser maiores do que as concentrações de poluentes em ambientes externos, portanto, a qualidade do ar interno é um dos itens mais importantes quando consideramos o ar-condicionado, pois auxilia na prevenção de doenças e garante o conforto dos usuários mantendo as mínimas condições de temperatura e umidade, dessa forma a manutenção como limpeza e desinfecção desses equipamentos é indispensável.
                            
                        </p>
                        <p>Há também no mercado outros equipamentos que promovem a esterilização e purificação do ar em ambientes internos trabalhando em conjunto ou não com aparelhos de ares-condicionados </p>
                    </main>
                    <header>
                        <img src="saude-ambiental-5.jpg" alt="saude-ambiental-5.jpg" />
                    </header>
                </div>
                
            </div>

        </Container>
    )
}