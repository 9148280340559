import { Container } from "./styled";

export function PlanOne({ icon, check1}: any) {
  return (
    <Container>
      <img src={icon} alt="icon" />

      {check1 ? (
        <img src="./planos/check.png" alt="icon" />
      ) : (
        <img src="./planos/cancelar.png" alt="icon" />
      )}
    </Container>
  );
}
