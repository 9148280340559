import { ReactNode } from "react";
import { Container } from "./styled";

interface IIcons {
    title: string;
    icon: ReactNode;
    mail: boolean;
}

export function Icons(
    { title, icon, mail }:IIcons
) {
    return (
        <Container>
            {mail ? (
                <a href="mailto:elefar@elefar.com.br">
                  {icon}
                    <p>{title}</p>
                </a>
            ) : (
                <a href={title}>
                    {icon}
                    <p>{title}</p>
                </a>
            )}

        </Container>
    )
}