import { Link } from "react-router-dom";
import { Container } from "./styled";

export function About() {
  return (
    <Container>
      <div className="section1">
        <img src="banner2.jpg" alt="alt" />

        <div>
          <nav>
            <Link to="/">Home</Link>
            <p> {">"} </p>
            <span>Sobre nós</span>
          </nav>

          <h2>Sobre nós</h2>
        </div>
      </div>

      <div className="section2">
        <div>
          <div className="img">
            <img src="detetizador.jpg" alt="detetizador" />
          </div>
          <span>
            Somos uma empresa jovem, moderna e destemida,{" "}
            <strong> reinvando o conceito de Saúde Ambiental.</strong>
          </span>
        </div>
        <div>
          <h3>Quem nós Somos</h3>
          <p>
            Nosso propósito é proprocionar um ambiente saudável, seguro e
            tranquilo, através de um serviço rapido, profissional e de
            exelência. Por isso, assumimos o compromisso de resolver seus
            problemas em até 48h, tomando todo os cuidados necessários,
            entregando tudo com segurança e conforto , zelando pelo seu
            bem-estar
          </p>
        </div>
      </div>

      <div className="section3">
        <div>
          <h3>Nosso Valor</h3>
          <p>
            <strong>
              Fazer o melhor com o que termos, para quando tivermos mais
              fazermos melhor ainda.
            </strong>
          </p>
          <ul>
            <li>Relações baseadas em ética e honestidade</li>
            <li>Transparência e objetividade</li>
            <li>Responsabilidade e competência</li>
            <li>Liderança em inovação e qualidade</li>
            <li>Empreendedorismo com eficiência</li>
            <li>Respeito ao próximo e com o meio ambiente</li>
          </ul>
        </div>
        <div>
          <span>
            <img src="visao.png" alt="visao" />
            <header>
              <h4>Visão</h4>
              <p>
                Ser destaque no ramo de atuação, buscando sempre inovação e
                novas tecnologiasExpandir com responsabilidade e segurança para
                que possamos atender nossos clientes cada dia melhor.
              </p>
            </header>
          </span>
          <span>
            <img src="valores.png" alt="valores" />
            <header>
              <h4>Valores</h4>
              <ul>
                <li>
                  {" "}
                  Fazer o melhor com o que termos, para quando tivermos mais
                  fazermos melhor ainda.
                </li>
                <li>Relações baseadas em ética e honestidade</li>
                <li> Transparência e objetividade</li>
                <li> Responsabilidade e competência</li>
                <li> Liderança em inovação e qualidade</li>
                <li> Empreendedorismo com eficiência</li>
                <li> Respeito ao próximo e com o meio ambiente</li>
              </ul>
            </header>
          </span>
        </div>
      </div>
    </Container>
  );
}
