import { Link } from "react-router-dom";
import { WindowSize } from "../../config/WindowSize";
import { useRef, useState } from "react";
import { Plan } from "./plan";
import { PlanOne } from "./PlanOne";
import InputMask from 'react-input-mask';
import { Container } from "./styled";
import emailjs from "@emailjs/browser";


export function Planos() {
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [problem, setProblem] = useState("");
  const [type, setType] = useState("");
  const { width } = WindowSize();
  
  const form = useRef();

  function sendEmail(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_30ma3xp",
        "template_4jtccky",
        form.current as any,
        "55dEuD9d1ATPxSP0z"
      )
      .then(
        (result: any) => {
          console.log(result.text);
        },
        (error: any) => {
          console.log(error.text);
        }
      );
    if (name !== "") {
      window.open(
        `https://api.whatsapp.com/send?phone=559294845086&text=Nome%3A%20${name
          .replaceAll(" ", "%20")
          .toLowerCase()}%0ATelefone%3A%20%20${tel}%0AEndereco%3A%20%20${address.replaceAll(
          " ",
          "%20"
        )}%0AEmail%3A%20${email}%0ADescreva%20Seu%20Problema%3A%20%20${problem.replaceAll(
          " ",
          "%20"
        )}%0ATipo%20De%20Problema%3A%20%20${type}`,
        "_blank"
      );
    }
    e.currentTarget.reset();
  }

  return (
    <Container>
      <div className="section1">
        <img src="banner2.jpg" alt="alt" />

        <div>
          <nav>
            <Link to="/">Home</Link>
            <p> {">"} </p>
            <span>Planos</span>
          </nav>

          <h2>Planos</h2>
        </div>
      </div>
      <div className="section2">
        <h2>Controle de pragas sem alterar a sua rotina</h2>

        <header>
          <h3>SELECIONE SEU PLANO</h3>

          <main>
            <div className="card">
              <span>Para a sua Residência</span>

              <img src="./planos/casa.png" alt="casa" />

              <p>
                Investimento: <strong>R$ 99,99/Mês</strong>
              </p>

              <a
                href="http://pag.ae/7YUnpgLB7"
                target={"_blank"}
                rel="noreferrer"
              >
                Contratar
              </a>
            </div>
            {width > 700 ? (
              ""
            ) : (
              <footer>
                <PlanOne icon="./planos/formiga_1.png" check1={true} />
                <PlanOne icon="./planos/barata_1.png" check1={true} />
                <PlanOne icon="./planos/silhueta-de-rato.png" check1={true} />
                <PlanOne icon="./planos/aranha.png" check1={true} />
                <PlanOne icon="./planos/forma-de-escorpiao.png" check1={true} />
                <PlanOne icon="./planos/mosquito.png" check1={true} />
                <PlanOne icon="./planos/torre-de-agua.png" check1={false} />
              </footer>
            )}
            <div className="card">
              <span>Para o seu apartamento</span>

              <img src="./planos/apartamento.png" alt="apartamento" />

              <p>
                Investimento: <strong>R$ 69,99/Mês</strong>
              </p>

              <a
                href="http://pag.ae/7YUnooRb8"
                target={"_blank"}
                rel="noreferrer"
              >
                Contratar
              </a>
            </div>
            {width > 700 ? (
              ""
            ) : (
              <footer>
                <PlanOne icon="./planos/formiga_1.png" check1={true} />
                <PlanOne icon="./planos/barata_1.png" check1={true} />
                <PlanOne icon="./planos/silhueta-de-rato.png" check1={true} />
                <PlanOne icon="./planos/aranha.png" check1={true} />
                <PlanOne icon="./planos/forma-de-escorpiao.png" check1={true} />
                <PlanOne icon="./planos/mosquito.png" check1={true} />
                <PlanOne icon="./planos/torre-de-agua.png" check1={false} />
              </footer>
            )}
            <div className="card">
              <span>Para a sua empresa</span>

              <img src="./planos/industria.png" alt="industria" />

              <p>Solicitar Orçamento</p>

              <a href="#section3">Contratar</a>
            </div>
            {width > 700 ? (
              ""
            ) : (
              <footer>
                <PlanOne icon="./planos/formiga_1.png" check1={true} />
                <PlanOne icon="./planos/barata_1.png" check1={true} />
                <PlanOne icon="./planos/silhueta-de-rato.png" check1={true} />
                <PlanOne icon="./planos/aranha.png" check1={true} />
                <PlanOne icon="./planos/forma-de-escorpiao.png" check1={true} />
                <PlanOne icon="./planos/torre-de-agua.png" check1={true} />
              </footer>
            )}
          </main>
          {width > 700 ? (
            <footer>
              <Plan
                icon="./planos/formiga_1.png"
                check1={true}
                check2={true}
                check3={true}
              />
              <Plan
                icon="./planos/barata_1.png"
                check1={true}
                check2={true}
                check3={true}
              />
              <Plan
                icon="./planos/silhueta-de-rato.png"
                check1={true}
                check2={true}
                check3={true}
              />
              <Plan
                icon="./planos/aranha.png"
                check1={true}
                check2={true}
                check3={true}
              />
              <Plan
                icon="./planos/forma-de-escorpiao.png"
                check1={true}
                check2={true}
                check3={true}
              />
              <Plan
                icon="./planos/torre-de-agua.png"
                check1={false}
                check2={false}
                check3={true}
              />
            </footer>
          ) : (
            ""
          )}
        </header>
      </div>

      <div id="section3">
        <form
          ref={form as any}
          onSubmit={sendEmail}
        >
          <header>
            <p>Solicite um orçamento </p>
           
          </header>
          <main>
            <section>
              <label htmlFor="">NOME:</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="name"
                required
              />
            </section>
            <section>
              <label htmlFor="">TELEFONE:</label>
              <InputMask
                name="phone"
                id="phone"
                mask="(99)99999-9999"
                required
                value={tel}
                onChange={(e) => {
                  setTel(e.target.value);
                }}
              />
            </section>
            <section>
              <label htmlFor="">ENDEREÇO:</label>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                name="address"
                required
              />
            </section>
            <section>
              <label htmlFor="">EMAIL:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                required
              />
            </section>
            <section>
              <label htmlFor="">TIPO DE ESTABELECIMENTO:</label>
              <select
                name=""
                id=""
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="Casa">Casa</option>
                <option value="Apartamento">Apartamento</option>
                <option value="Comercio">Comercio</option>
                <option value="Indústria">Indústria</option>
                <option value="Clinica de saúde">Clínica de saúde</option>
              </select>
            </section>
            <section>
              <label htmlFor="">DESCREVA SEU PROBLEMA:</label>
              <textarea
                value={problem}
                onChange={(e) => setProblem(e.target.value)}
                name="email"
                required
              />
            </section>
          </main>
          <footer>
            <button type="submit">Solicite Agora</button>
          </footer>
        </form>
      </div>
    </Container>
  );
}
