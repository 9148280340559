import {createGlobalStyle} from 'styled-components'

export const GlobalStyle = createGlobalStyle`
:root{
    --background:#f0f2f5;
    --red:#E52E4D;
    --blue:#25CAEE;
    --blue300:#3D7ABE;
    --orange:#ECB453;
    --orange300:#FFD792;
    --purble:#6933ff;
    --text-title:#363f5f;
    --text-body:#969cb3;
    --gray:#3D3939;
    --gray800:#696464;
    --shape:#FFFFFF;
    --green:#33cc95;
    --footer:#354057;
    --footer1:#2B3237;

}
*{
    margin:0;
    padding:0;
    box-sizing:border-box;
}

html{
    @media(max-width:1080px){
        font-size:93.75%; //15px
    }
    @media(max-width:720px){
        font-size:87.5%; // 14px
    }
}

body{
    background: var(--background); 
    -webkit-font-smoothing:antialiased;

}
body, input, textarea, button{
    font-family:'Roboto', sans-serif;
    font-weight:400;
}

h1,h2,h3,h4,h5,h6{
    font-weight: bold;
}

button{
    cursor:pointer
}
a{
    text-decoration: none;
}
.react-modal-overlay{
    background-color: rgba(0,0,0,0.5);

    position:fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display:flex;
    align-items: center;
    justify-content: center;
}

.react-modal-content{
    width:100%;
    max-width: 576px;
    background: var(--background);
    position: relative;
    padding:3rem;
    border-radius: 0.25rem;
}

.react-modal-close{
    position: absolute;
    right: 1.5rem;
    top:1.5rem;
    border:0;
    background: transparent;

    transition:filter 0.4s;

    &:hover{
        filter:brightness(0.8)
    }

}

`