import styled from "styled-components";

export const Container = styled.div`
width: 100%;
color: var(--shape);

.section1{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 648px;
    overflow: hidden;
    video{
        width: 100%;
       
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }
    .text{
        width:850px;
        font-size: 2.4rem;
        line-height: 3.2rem;
        display: flex;
        flex-direction: column;
        align-items: start;
       
        strong{
            color:var(--orange);
            font-weight:bold
        }
        span{
            margin-top: 2rem;
            color:var(--orange);
            font-weight:bold
        }
        button{
            display: none;
        }
    }
    form{
        
        width: 351px;
        height: 556px;
        background-color: rgba(0, 0, 0, 0.53);
        border-radius: 1rem ;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        header{

            background-color: var(--shape);
            color: var(--blue300);
            padding: .75rem 1rem;
            border-radius: 1rem 1rem 0rem 0rem;
            font-weight: bold;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            button{
                border: 0;
                background-color: transparent;
                font-size: 1.5rem;
            }
        }
        main{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding:1rem;
   
            section{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top:1rem;

            &:first-child{
                margin-top:0;
            }
            input, select, textarea{
                background-color: transparent !important;
                border: none;
                border-bottom: 1px solid var(--shape);
                color:var(--shape);
                margin-top:.5rem;
                padding: 0 .25rem;
            }
            option{
                background-color: transparent !important;
                color: #000;
            }

            }
        }
        footer{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding:2rem;
            button{
                padding:1rem 3rem;
                background-color: var(--orange);
                color: var(--shape);
                border: none;
                border-radius: 2rem;
                font-size: 1.1rem;
                font-weight: bold;
                transition: all 0.4s;

                &:hover{
                    background-color: var(--shape);
                color:var(--orange);
                }
            }
           
        }
    }

   
    @media (max-width:900px){
        height: auto;
        padding:2rem;
        overflow: hidden;
        height:600px;
        video{
            width:900px;
            height:500px;
        }
        form{
          
          position: absolute;
          background-color: rgba(0, 0, 0, 0.9);

            &.none{
                display: none;
            }

        }
        .text{
            width: 100%;
            font-size: 1.7rem;

            button{
                margin: 0 auto;
                margin-top: 2rem;
                display: block;
                padding:1rem 3rem;
                background-color: var(--orange);
                color: var(--shape);
                border: none;
                border-radius: 2rem;
                font-size: 1.1rem;
                font-weight: bold;
                transition: all 0.4s;

                &:hover{
                    background-color: var(--shape);
                color:var(--orange);
                }
            }
        }
    }
    @media (max-width:600px){
      
        video{
          min-width:600px;
          height:600px;
          right: -2rem;
      }
    
  }
  @media (max-width:450px){
    video{
          right: -15rem;
      }
  }
}
.section2{
    width: 100%;
    padding: 4rem 3rem;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    position: relative;
    .title{
    padding-bottom: 3rem;
    text-align: center;
        h2{
            margin-bottom: 1rem;
            font-size: 3.5rem;
            color: var(--blue300);
            &:first-child{
                margin-right: 30rem;
            }
        }
        div{
            width:125px;
            height: .2rem;
            margin: 0 auto;
            background: var(--orange);
        }

    }
    .text{
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        p{
            text-align: justify;
            max-width: 700px;
            color: #000;
            line-height: 2.5rem;
            font-size: 1.7rem;
        }
    }
    .img{
        width:418px;
        height: 402px;
        overflow: hidden;

        img{
           width: 100%;
        }
        p{
        
        }
    }

    @media(max-width:1000px){
        .title{
            h2{
            font-size: 2rem;
            &:first-child{
                margin-right: 0rem;
            }
        }   
        }
        .text{
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.3rem;
            .img{
                width: 95%;
                margin-bottom: 3rem;
            }
            p{
            max-width: 700px;
            color: #000;
            line-height: 2.5rem;
            font-size: 1.4rem;
        }
        }
    }
}

.section3{
    width: 100%;
    padding: 4rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width: 90%;
    }
    @media(max-width:900px){
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding: 4rem 0;
        min-height: 2800px;
        img{
            position: absolute;
            width:60%;
        }
    }
    @media(max-width:800px){
        min-height: 2500px;
    }

    @media(max-width:700px){
        min-height: 2000px;
    }
    @media(max-width:600px){
        min-height: 1500px;
    }
    @media(max-width:460px){
        min-height: 1000px;

        img{
            width:50%
        }
    }
    @media(max-width:375px){
        min-height: 800px;

        img{
            width:40%
        }
    }

}
.section4{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2{
        color:var(--blue300);
        font-size: 2.5rem;
        padding-bottom: 3rem;
    } 

    .slide{
        
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        justify-content: space-around;
    }
    @media(max-width:500px){
        text-align: center;
    }
}
.section5{
    @media(min-width:1360px){
        div{
            justify-content: center;
          
        }
        
     }
    width: 100%;
    background-color: var(--shape);
    position: relative;
    padding: 6rem 0;
    
    div{
        
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: var(--shape);
  
        header{
            position: relative;
            width: 20vw;
            z-index: 2;
            &::before{
                content: '';
                position: absolute;
                width: 20vw;
                height: 100%;
                background: var(--blue300);
                top: 1rem;
                left: 1rem;
                z-index: 1;
            }


            img{
                position: relative;
                width: 20vw;
                z-index: 2;
            }
        
        }
        main{
            width:65vw;
            display: flex;
            flex-direction: column;
            align-items: end;
            text-align: end;
             h2{
                width:55vw;
                color:#3D3939;
                font-size: 4rem;
                padding-bottom: 2rem;
            }
            header{
                display: none;
            }
            p{
                color:#696464 ;
                font-size: 2rem;
              
            }

        }
    }
     @media(max-width:800px){
        div{
            flex-direction: column;
            justify-content: center;
            align-items:center;
            header{
                display: none;
             
            }
            main{
            text-align: center;
            width:100%;
            header{
            display: block;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 4rem;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
              
            }


            img{
                position: relative;
                width: 100%;
                z-index: 2;
               
            }
        }
            h2{
                width:100%;
              
                font-size: 3rem;
                padding-bottom: 2rem;
            }
            }
        }
        
     }
     @media(max-width:400px){
        div{
            
            main{
            text-align: center;
            width:100%;
            header{
            display: block;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 4rem;
                &::before{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                
                }


                img{
                    position: relative;
                    width: 100%;
                    z-index: 2;
                
                }
            }
            h2{
                width:100%;
              
                font-size: 2rem;
                padding-bottom: 2rem;
            }
            p{
                color:#696464 ;
                font-size: 1rem;
              
            }
        }
        }
        
     }

    
}
.section6{
    width: 100%;
    height: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding-bottom: 4rem;

    .img{
        width: 100%;

        &:before{
            content:"";
            position: absolute;
            width:100%;
            height: 100%;
            background-color: rgba(0,0,0,0.3);
            top: 0;
            left: 0;
            z-index: 2;
        }
        img{
        width: 100%;
        position: absolute;
        top: -20rem;
        left: 0;
        z-index:1;

        &:last-child{
            display: none;
        }
        }
    }
    
    .text{
        position: relative;
        z-index: 2;

        h2{
            margin: 4rem;
            max-width: 1000px;
            font-size: 3.5rem;
            font-weight: normal;
            
            line-height: 5.5rem;

            span{
                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: var(--blue300);
                    z-index: -2 !important;
                 
                }
                &:first-child:after{
                    top: -.5rem;
                    left: -.5rem;
                    z-index: -2 !important;
                }
                &:last-child::after{
                    top: .5rem;
                    left: .5rem;
                    z-index: -2 !important;
                }
                position: relative;
                background-color: var(--shape);
                padding: 0.3rem;
                color: var(--blue300);
   
               
            }
        }
        a{
   
            text-decoration: none;
            color: var(--shape);
            background-color: var(--orange);
            padding: 1.5rem;
            font-weight: bold;
            font-size: 1.1rem;
            border-radius: 3rem;
             transition: all 0.4s;

            &:hover{
                color: var(--orange);
             background-color: var(--shape);
            }
        }
    }

    @media(max-width:1000px){
        .text{
            h2{
                margin: 2.5rem;
                font-size: 3rem;
                line-height: 4.5rem;
            }
        }

    }
    @media(max-width:750px){
        .text{
            h2{
                font-size: 2.5rem;
                line-height: 4rem;
            }
        }
        .img{
            img{
            top: -10rem;
            }
        }

    }
    @media(max-width:670px){
        height:auto;

        .img{
            img{
            top: -5rem;
            &:last-child{
                display: block;
            }
            }
        }

    }

    @media(max-width:500px){
        height:auto;
        .text{
            h2{
                font-size: 1.5rem;
                line-height: 3rem;
            }
            a{
            font-size: .7rem;
                &:hover{
                    color: var(--orange);
                    background-color: var(--shape);
                }
            }
        }
        .img{
            img{
            top: -5rem;
            &:last-child{
                display: block;
            }
            }
        }

    }
    
    @media(max-width:400px){
        height:auto;
        .text{
            h2{
                font-size: 1rem;
                line-height: 2.2rem;
                margin: 1.4rem 0;
                
            }
            a{
                font-size: .7rem;
                padding:1rem;
            }
        }
        .img{
            img{
                
                top: -5rem;
                margin-bottom: 1rem;
            &:last-child{
                display: block;
            }
            }
        }

    }
    
}
.section7{
    padding: 3rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    background-color: var(--shape);
    h2{
        color:var(--blue300);
        font-size: 2.5rem;
        text-align: center;
        width:95%
    } 
    div{
     
        margin-top: 2rem;
        width: 95%;
        display: flex;
        flex-direction: row ;
        justify-content: space-evenly ;
        align-items: center;
        flex-wrap:wrap;
      span{
        width:100px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        
        img{
            filter: grayscale(100%);
            width: 90%;
            transition: all 0.4s;
            &:hover{
                filter: grayscale(0%);
            }
        }
      }
    }
 
    
}




`