import styled from "styled-components";

interface IContent{
    show:boolean

}

export const Container = styled.header`
width:100%;
margin:0 auto;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: var(--shape);
`

export const Main = styled.div<IContent>`
    width:90%;
    display: flex;
    align-items:center;
    padding:.1rem 0;

    .icon{
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        padding: 2rem;
        z-index:9999;
        svg{
            width: 2rem;
            height: auto;
            color: var(--blue300);
        }

        @media(max-width:650px){
            display: ${(props)=>props.show ? "none": "flex"};
        }
    }

    img{
        width:5rem;
        height: auto;
    }
    main{
        margin-left: 3%;
        display: flex;
        justify-content: space-between;
    }
    .client{
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .link{
            position: absolute;
            width:188px;
            text-align: center;
            padding: .5rem;
            background-color: var(--blue);
            color:var(--shape);
            font-weight: bold;
            top:0;
        
            border-radius: 0rem 0rem 1rem 1rem;
            text-decoration: none;
            box-shadow: 0px 0px 5px 3px #ccc;
            @media(min-width:1000px ){
                right:4rem;
            }
        }
        div{
        display: flex;
        width:188px;
        align-items: end;
        height: 3.5rem;
        display: flex;
        justify-content: space-around;

        svg{
            
            font-size: 1.2rem;
            &.whatsapp{
                fill: #46C656;
            }
            &.facebook{
                fill:#3D7ABE
            }
            &.instagram{
                fill: #F0748A;
            }
        }
        }
    }

    @media(max-width:850px){
        justify-content: space-between;
        main{
        margin-left: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        }
        .client{
            margin-left: 0;
            
        }
    }
    @media(max-width:660px){
        flex-direction: column;
        align-items: center;
        img{
        width:7rem;
        margin: 1.5rem;
        height: auto;
        }
        main{
          
        
        }
        .client{
            div{
                margin-bottom: 1rem;
            }
       
                .link{
                margin: 0 auto;
            }
            
        }
    }

`

export const Content = styled.nav<IContent>`
width: 100%;
display: flex;
align-items: center;
justify-content: end;
background-color: var(--blue300);
padding:1rem 0rem;
ul{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 65%;
    list-style: none;
    a{
        text-decoration: none;
        font-weight: bold;
        color: var(--shape);
        transition: all 0.4s;

        &:hover{
            color:var(--blue)
        }

    }
}
div{
    display: none;
}
@media(max-width:650px){
    div{
    display: block;
    }
    display: ${(props)=>props.show ? "flex": "none"};
    top: 0;
    left: 0;
    position: fixed;
    width:100%;
    height: 100vh;
    z-index:999;
    background-color: var(--blue300);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div{
        position: absolute;
        top: 0;
        right: 0;
        padding: 2rem;
        svg{
            width: 2rem;
            height: auto;
            color: var(--shape);
        }
    }
    ul{
        flex-direction: column;
        align-items: center;
        justify-content: start;

        li{
            margin-bottom: 2rem;
            a{
                font-size: 2rem;
                
            }
        }
    }
}


`