import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: var(--orange300);
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 2rem;
  margin-bottom: 30px;
  position: relative;
  justify-content: center;
  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    
    &:first-child {
      width: 45px;
      height: 45px;
      position: relative;
      background-color: #f1f1f1;
      position: absolute;
      left: 0;
    }

  }
`;
