import { Container } from "./styled";

interface Iinfo {
    number: string;
    title: string;
    text: string

}

export function Info({
    number, title, text
}: Iinfo) {

    return (
        <Container number={parseInt(number)}>
            <div >
                <span>{number}</span>
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
        </Container>
    )
}