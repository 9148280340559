import { Link } from "react-router-dom";
import { Container } from "./styled";



export function Contact() {
    return (
        <Container>
            <div className="section1">
                <img src="banner2.jpg" alt="alt" />

                <div>

                    <nav>
                        <Link to="/">Home</Link>
                        <p> {'>'} </p>
                        <span>Contato</span>
                    </nav>

                    <h2>Contato</h2>

                </div>

            </div>
            <div className="section2">
                <div className="div">
                    <h3>Envie uma Mensagem</h3>

                    <form action="">
                        <fieldset>
                            <input type="text" placeholder="Nome*" />
                            <input type="text" placeholder="E-mail*" />
                        </fieldset>
                        <fieldset>
                            <input type="text" placeholder="Telefone*" />
                            <input type="text" placeholder="Endereço*" />
                        </fieldset>

                        <textarea placeholder="Mensagem">

                        </textarea>
                    </form>
                </div>
                <div className="div">
                    <h3>Entre em Contato</h3>

                    <span>
                        <strong>Telefone: </strong>
                        <p> (92) 3022-9228 </p>
                    </span>
                    <span>
                        <strong>Celular:</strong>
                        <p>(92) 99511-7987 / <br />
                            (92) 99484-5086</p>
                    </span>
                    <span>
                        <strong>Email: </strong>
                        <p>elefar@elefar.com.br</p>
                    </span>
                </div>
            </div>


        </Container>
    )
}