import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #f1f1f1;
  color: var(--shape);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  .section1{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    overflow: hidden;
    &::before{
                content: '';
                position: absolute;
                width:  100%;
                height: 100%;
                background-color: rgba(0,0,0,0.3);
                top: 0rem;
                left: 0rem;
                z-index: 1;
        }
    img{
        width: 100%;

        min-width: 1300px;
        position: absolute;
        left: 0;
        top: -15rem;
        z-index: 0;
        
    }

    div{
        position: relative;
        z-index: 4;
        text-align: center;
        font-size: 1.3rem;

        nav{
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            a{
                color: var(--shape);
                text-decoration: none;
            }
            p{
                margin:0 1rem
            }
        }
    }
    
}
  .section2 {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h2{
      color:var(--blue300);
      font-size: 50px;
      margin-top: 20px;
      margin-bottom: 60px;
    }
    header {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      width: 95%;
      h3{
        color:var(--blue300);
        margin-bottom: 30px;
        font-size: 30px;
      }
      main{
        width:100%;
        display: flex;
        justify-content: space-between;
        .card{
            width: 30%;
            height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            background-color: #fff;
            box-shadow: 10px 10px 5px #ccc;
            border-radius: 1rem;
            span{
                background: var(--blue300);
                color: var(--shape);
                width: 70%;
                border-bottom-left-radius: 2rem;
                border-bottom-right-radius: 2rem;
                padding: 0.25rem;
                font-weight: bold;
                text-align: center;
            }

            img{
                height: 60px;
                margin: 3rem 0;
                width: auto;
            }

            p{
                color: var(--blue300);
                font-size: 21px;
                strong{
                    color:var(--orange);
                }
         
            }

            a{
                padding: 0.5rem 2rem;
                border-radius: 30px;
                color:var(--shape);
                background-color:var(--orange);
                margin: 2rem 0;
            }
        }
        @media(max-width:1000px){
            .card{
            span{
             font-size:.7rem;
            }

            img{
                height: 40px;
                margin: 3rem 0;
                width: auto;
            }

            p{
                color: var(--blue300);
                font-size: 21px;
                strong{
                    color:var(--orange);
                }
         
            }

            a{
                padding: 0.5rem 2rem;
                border-radius: 30px;
                color:var(--shape);
                background-color:var(--orange);
                margin: 2rem 0;
            }
        }

        }
        @media(max-width:700px){
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .card{
            width:70%;

          }
          footer{
            flex-direction: column;
          justify-content: center;
          align-items: center;
            div{
              width:70%;
            }
          }
        }
      }

      footer{
        margin: 60px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
      }
    }
  }
  #section3{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 648px;
    overflow: hidden;
    video{
        width: 100%;
       
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }
    .text{
        width:850px;
        font-size: 2.4rem;
        line-height: 3.2rem;
        display: flex;
        flex-direction: column;
        align-items: start;
       
        strong{
            color:var(--orange);
            font-weight:bold
        }
        span{
            margin-top: 2rem;
            color:var(--orange);
            font-weight:bold
        }
        button{
            display: none;
        }
    }
    form{
        
        width: 70%;
        min-width: 300px;
        height: 556px;
        background-color: rgba(0, 0, 0, 0.53);
        border-radius: 1rem ;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        box-shadow: 10px 10px 5px #ccc;
        header{

            background-color: var(--shape);
            color: var(--blue300);
            padding: .75rem 1rem;
            border-radius: 1rem 1rem 0rem 0rem;
            font-weight: bold;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            button{
                border: 0;
                background-color: transparent;
                font-size: 1.5rem;
            }
        }
        main{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding:1rem;
   
            section{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top:1rem;

            &:first-child{
                margin-top:0;
            }
            input, select, textarea{
                background-color: transparent !important;
                border: none;
                border-bottom: 1px solid var(--shape);
                color:var(--shape);
                margin-top:.5rem;
                padding: 0 .25rem;
            }
            option{
                background-color: transparent !important;
                color: #000;
            }

            }
        }
        footer{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding:2rem;
            button{
                padding:1rem 3rem;
                background-color: var(--orange);
                color: var(--shape);
                border: none;
                border-radius: 2rem;
                font-size: 1.1rem;
                font-weight: bold;
                transition: all 0.4s;

                &:hover{
                    background-color: var(--shape);
                color:var(--orange);
                }
            }
           
        }
    }

   
  }
`;
