/* eslint-disable react/jsx-no-comment-textnodes */

import { Link } from "react-router-dom";
import { Container, } from "./styled";

export function Footer() {
    return (
        <Container>
            <header>
                <div>
                    <h4>SOBRE NOS</h4>
                    <hr />
                    <p>Somos uma empresa jovem, moderna e destemida,
                        <strong>
                            reinvando o conceito de Saúde Ambiental.
                        </strong>
                    </p><br />
                    <p>Nosso propósito é{' '} 
                        <strong>
                            proprocionar um ambiente saudável, seguro e tranquilo,
                        </strong>
                        através de um serviço rapido, profissional e de exelência.</p> <br />
                    <p>Por isso, <strong>assumimos o compromisso de resolver seus problemas em até 48h, </strong> tomando todos os cuidados necessários, entregando tudo com segurança e conforto , zelando pelo seu bem-estar</p>
                </div>
                <div>
                    <h4>MENU</h4>
                    <hr />
                    <nav>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">Sobre nós</Link></li>
                            <li><Link to="/">Saúde Ambiental</Link></li>
                            <li><Link to="/contact">Contato</Link></li>

                        </ul>
                    </nav>
                </div>
                <div>
                    <h4>CONTATO</h4>
                    <hr />
                    <span><strong>Telefone:</strong> <a href="tel:+559230229228 ">(92) 3022-9228 </a></span>
                    <span><strong>Celular:</strong> <a href="tel:+5592995117987">(92) 99511-7987 /
                    </a><a href="tel:+559299484-5086">(92) 99484-5086</a></span>
                    <span><strong>Email:</strong> <a href="mailto: elefar@elefar.com.br ">elefar@elefar.com.br
                    </a></span>
                </div>
                
            </header>
            <footer >
               <p> © 2022 Agencia Trend - All rigth reserved</p>
            </footer>
        </Container>
    )
}