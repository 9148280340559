import styled from "styled-components";

export const Container = styled.div`
    width:25vw;
    height: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 1rem;
    position: relative;
    &:before{
            content:"";
            position: absolute;
            width:100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
            top: 0;
            left: 0;
            z-index: 2;
        }
    .slide{
        width:25vw;
        height: 25vw;
        
    }
    div{
        opacity: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:25vw;
        height: 25vw;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        background-color:var(--blue300);
        transition: all 0.4s;
        img{
            background-color: var(--shape);
            width: 7vw;
            padding: .5rem;
            border-radius: 3rem;
        }

        p{
            margin: 2rem 0;
            font-weight: bold;
            font-size: 1.2rem;
        }
        hr{
            width: 4rem;
        }
        
    }
    &:hover div {
        opacity: 100%;
       
    }

    @media(max-width:500px){
        width:200px;
        height: 200px;
        .slide{
            width:200px;
            height: 200px;
        }
        div{
            width:200px;
            height: 200px;
        }
    }
`