import { Container } from "./styled";

export function Plan({ icon, check1, check2, check3 }: any) {
  return (
    <Container>
      <img src={icon} alt="icon" />

      {check1 ? (
        <img src="./planos/check.png" alt="icon" />
      ) : (
        <img src="./planos/cancelar.png" alt="icon" />
      )}
      {check2 ? (
        <img src="./planos/check.png" alt="icon" />
      ) : (
        <img src="./planos/cancelar.png" alt="icon" />
      )}
      {check3 ? (
        <img src="./planos/check.png" alt="icon" />
      ) : (
        <img src="./planos/cancelar.png" alt="icon" />
      )}
    </Container>
  );
}
