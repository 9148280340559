import styled from "styled-components";

export const Container = styled.div`
width: 100%;
color: var(--shape);

.section1{
    width: 100%;
   
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    overflow: hidden;
    &::before{
                content: '';
                position: absolute;
                width:  100%;
                min-width: 1300px;
                height: 100%;
                background-color: rgba(0,0,0,0.3);
                top: 0rem;
                left: 0rem;
                z-index: 1;
            }
    img{
        width: 100%;
        min-width: 1300px;
        position: absolute;
        right: 0;
        top: -20rem;
        z-index: -1;

        @media(max-width:800px){
            right:-15rem
        }
    }

    div{
        position: relative;
        z-index: 4;
        text-align: center;
        font-size: 1.3rem;

        nav{
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            a{
                color: var(--shape);
                text-decoration: none;
            }
            p{
                margin:0 1rem
            }
        }
    }
   
}
.section2{
    width: 100%;
    padding:4rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--shape);
    .img{
        width:523px;
        height: 334px;
        overflow: hidden;
        position: relative;
        img{
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    div{
        
        width:50%;
     
        position: relative;
        h3{
            color: var(--blue300);
            font-size: 26px;
        }
        p{
            color: #000;
            font-size: 20px;
            line-height: 2rem;
            letter-spacing: 0.185em;
            text-align: justify;
        }

        span{
            color: #000;
            width: 25rem;
            height: 15rem;
            position: absolute;
            background-color: var(--shape);
            border-top: .5rem solid var(--blue300);
            border-radius: .5rem;
            right:3rem;
            bottom: -10rem;
            font-size: 30px;
            text-align: center;
            padding: 1.5rem;
            box-shadow: 5px 5px 10px  #ccc;
        }
    }


    @media(max-width:1200px){
        .img{
        width:80%;
        }
    }
    @media(max-width:900px){
        div{
        
            width:50%;
     
            position: relative;
        h3{
            color: var(--blue300);
            font-size: 26px;
        }
        p{
            color: #000;
            font-size: 20px;
            line-height: 1.3rem;
            letter-spacing: 0.185rem;
        }

        span{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 18rem;
            height: 10rem;
            right:3rem;
            bottom: -2rem;
            font-size: 18px;
            text-align: center;
            padding: 1rem;
            box-shadow: 5px 5px 10px  #ccc;
        }
    }


    }
    @media(max-width:850px){
        align-items: start;
        div{
        
        p{
            font-size: 15px;
        }

        span{
            bottom: 0rem;
            font-size: 18px;
            text-align: center;
        }
    }


    }
    @media(max-width:700px){
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding:5rem 0rem;
        .img{
        top: 0;
        left: 0;
        width:100%;
        height: auto;
        img{
            width: 100%;
            position: relative;
        }
        }
        div{
        width: 80%;
        margin-bottom: 5rem;
        &:last-child{
            margin-bottom: 0rem;
        }

        span{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 25rem;
            height: 15rem;
            right:-2rem;
            bottom: -3rem;
            font-size: 23px;
            text-align: center;
            padding: 1rem;
            box-shadow: 5px 5px 10px  #ccc;
        }
    }


    }
    @media(max-width:450px){
        
        div{
        span{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 20rem;
            height: 10rem;
            right:-2rem;
            bottom: 1rem;
            font-size: 17px;
            text-align: center;
            padding: 1rem;
            box-shadow: 5px 5px 10px  #ccc;
        }
    }


    }
}

.section3{
    width: 100%;
    background-color: var(--shape);
    display: flex;
    align-items: start;
    padding: 4rem;
    justify-content: space-between;
    color: #000;
    padding-top: 13rem;
    h3, h4{
        color: var(--blue300);
        font-size: 1.5rem;
    }
    p{
        padding: 1rem 0;
    }
    strong{

        font-size: 2rem;
      
    }
    div{
        width: 45%;
    }

    ul{
        list-style: none;
        margin-top: 1rem;
        li{
            color: rgba(0, 0, 0, 1);
            line-height: 1.5rem;
            ::before {content: "•"; color: var(--blue300);
            display: inline-block; width: 1em;
            font-size: 1.5rem;
            margin-left: -1em}
        }
    }
    span{
        display: flex;
        align-items: start;
        margin-top: 3rem;
        background-color: #FBFBFB;
        padding: 1rem;
        img{
            padding: .5rem;
            background-color: var(--blue300);
            width: 3rem;
            border-radius: .5rem;
            margin: 0 1rem;
        }
    }
    @media(max-width:700px){
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding-top: 5rem;
        div{
            width: 100%;
        }
    }
    @media(max-width:500px){
        
        h3, h4{
            font-size: 1.7rem;
            width: 95%;
        }
        strong{
            font-size: 1.5rem;
        }
    }
    @media(max-width:400px){
        
        h3, h4{
            font-size: 1.2rem;
            width: 95%;
        }
        strong{
            font-size: 1rem;
        }
        p{
        padding: .5rem 0;
        font-size: .8rem;
        }
        ul{
        list-style: none;
        margin-top: .5rem;
        li{
            font-size: .9rem;
            line-height: 1.3rem;
        }
        }
    }
}





`