import styled from "styled-components";

export const Container = styled.div`
width: 100%;
color: var(--shape);
.banner{
    width: 100%;
   
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    overflow: hidden;
    &::before{
                content: '';
                position: absolute;
                width:  100%;
                min-width: 1300px;
                height: 100%;
                background-color: rgba(0,0,0,0.3);
                top: 0rem;
                left: 0rem;
                z-index: 1;
            }
    img{
        width: 100%;
        min-width: 1300px;
        position: absolute;
        right: 0;
        top: -20rem;
        z-index: -1;

        @media(max-width:800px){
            right:-15rem
        }
    }

    div{
        position: relative;
        z-index: 4;
        text-align: center;
        font-size: 1.3rem;

        nav{
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            a{
                color: var(--shape);
                text-decoration: none;
            }
            p{
                margin:0 1rem
            }
        }
    }
   
}

.section1{
    @media(min-width:1360px){
        div{
            justify-content: center;
          
        }
        
     }
    width: 100%;
    background-color: var(--background);
    position: relative;
    padding: 6rem 0;
    
    div{
        
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: var(--background);
  
        header{
            position: relative;
            width: 30vw;
            z-index: 2;
            &::before{
                content: '';
                position: absolute;
                width: 30vw;
                height: 100%;
                background: #295381;
                top: -1rem;
                right: 1rem;
                z-index: 1;
            }


            img{
                position: relative;
                width: 30vw;
                z-index: 2;
            }
        
        }
        main{
            width:50vw;
            display: flex;
            flex-direction: column;
            align-items: start;
            text-align: justify;
            
            header{
                display: none;
            }
            p{
                color:#295381 ;
                font-size: 2.3rem;
            }

        }
    }
     @media(max-width:900px){
        div{
            flex-direction: column-reverse;
            justify-content: center;
            align-items:center;
            header{
                display: none;
             
            }
            main{
            flex-direction: column-reverse;
            text-align: center;
            width:100%;
            header{
            display: block;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 4rem;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
              
            }


            img{
                position: relative;
                width: 100%;
                z-index: 2;
               
            }
        }
            h2{
                width:100%;
              
                font-size: 3rem;
                padding-bottom: 2rem;
            }
            }
        }
        
     }
     @media(max-width:400px){
        div{
            
            main{
            text-align: center;
            width:100%;
            header{
            display: block;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 4rem;
                &::before{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                
                }


                img{
                    position: relative;
                    width: 100%;
                    z-index: 2;
                
                }
            }
            h2{
                width:100%;
              
                font-size: 2rem;
                padding-bottom: 2rem;
            }
            p{
                color:#696464 ;
                font-size: 1rem;
              
            }
        }
        }
        
     }

    
}

.section2{
    width: 100%;
    background-color: var(--shape);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        position: absolute;
        width: 60%;
        z-index: 1;
       opacity: 0.2;
    }
    div{
        position: relative;
        z-index: 2;
        padding: 4rem;
        h2{
        font-size: 2.8rem;
        color: var(--blue300);
        width: 50%;

        }
        p{
            color: #000;
            font-size: 1.7rem;
            margin: 2rem 0;
        }
        @media(max-width:900px){
        h2{
        width: 95%;

        }
        p{
        
            font-size: 1.7rem;
            margin: 2rem 0;
        } 
    }
        @media(max-width:500px){
        h2{
            font-size: 1.5rem;

        }
        p{
        
            font-size: 1.2rem;
            margin: 2rem 0;
        } 
    }

    }
    
}

.section3{
    @media(min-width:1360px){
        div{
            justify-content: center;
          
        }
        
     }
    width: 100%;
    background-color: var(--shape);
    position: relative;
    padding: 6rem 0;
    
    div{
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-around;
    background-color: var(--shape);
    margin-bottom: 3rem;
  
        header{
            position: relative;
            width: 40vw;
            z-index: 2;
            &::before{
                content: '';
                position: absolute;
                width: 40vw;
                height: 100%;
                background:  #295381;
                top: 1rem;
                left: 1rem;
                z-index: 1;
            }


            img{
                position: relative;
                width: 40vw;
                z-index: 2;
            }
        
        }
        main{
            width:50vw;
            display: flex;
            flex-direction: column;
            align-items: start;
            text-align: start;
             h2{
                width:25vw;
                color: #295381;
                font-size: 2.7rem;
                padding-bottom: 2rem;
                text-align: end
            }
            header{
                display: none;
            }
            

        }
       
    }
    p{
        width: 90%;
        color:#696464 ;
        font-size: 1.5rem;
        margin: 0 auto;
        text-align: justify;
    }
     @media(max-width:800px){
        div{
            flex-direction: column;
            justify-content: center;
            align-items:center;
            
            header{
                display: none;
             
            }
            main{
            text-align: center;
            width:100%;
            header{
            display: block;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 4rem;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
              
            }


            img{
                position: relative;
                width: 100%;
                z-index: 2;
               
            }
            }
            p{
                text-align:start;
            }
            h2{
                width:100%;
                text-align:center;
                font-size: 3rem;
                padding-bottom: 2rem;
            }
            
            }
        }
        
     }
     @media(max-width:400px){
        div{
            
            main{
            text-align: center;
            width:100%;
            header{
            display: block;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 4rem;
                &::before{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                
                }


                img{
                    position: relative;
                    width: 100%;
                    z-index: 2;
                
                }
            }
            h2{
                width:100%;
              
                font-size: 2rem;
                padding-bottom: 2rem;
            }
            p{
                color:#696464 ;
                font-size: 1rem;
              
            }
        }
        }
        
     }

    
}

.section4{
    width: 100%;
    height: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: start;
    position: relative;
    overflow: hidden;
    .img{
        width: 100%;

        &:before{
            content:"";
            position: absolute;
            width:100%;
            height: 100%;
            background-color: rgba(0,0,0,0.3);
            top: 0;
            left: 0;
            z-index: 2;
        }
        img{
        width: 100%;
        position: absolute;
        top: 0rem;
        left: 0;
        z-index:1;

        &:last-child{
            display: none;
        }
        }
    }
    
    .text{
        position: relative;
        z-index: 2;

        h2{
            font-size: 3rem;
            font-weight: bold;
            width: 60%;
            margin-left: 2rem;
            span{
                font-size: 4rem;
            }
        }
        
    }

    @media(max-width:1000px){
        .text{
            display: flex;
            height: 100%;
            align-items: center;
            h2{
                font-size: 2rem;
                width: 90%;
                span{
                font-size: 3rem;
            }
            }
        }
    }
  
    @media(max-width:670px){
        .text{
            h2{
            font-size: 1.8rem;
            span{
                font-size: 2rem;
            }
        }
        }
    }

    @media(max-width:500px){
      
        .text{
            h2{
            font-size: 1.2rem;
                span{
                font-size: 1.5rem;
                }
            }
        }
        

    }

    
    @media(max-width:400px){
      
      .text{
          h2{
          font-size: 1rem;
              span{
              font-size: 1.2rem;
              }
          }
      }
      

  }
    
    
    
}

.section5{
    margin: 3rem 0;
    
    section{
        width: 95%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        img{
        width: 45%;
        height: auto;
        }
        
        p{
            color: #000000;
            font-size: 1.6rem;
            strong{
                color: #037aff
            }
            margin-bottom: 3rem;
        }

        div{
            position: relative;
            z-index: index 2;
            background-color: var(--background);
            padding: 1rem;
            &::before{
                content: '';
                position: absolute;
                width: 80%;
                height: 100%;
                background: #295381;
                bottom: -1rem;
                right: -1rem;
                z-index: -1;
            }
        }
        @media(max-width:900px){
        flex-direction: column;
        img{
        width: 80%;
        height: auto;
        }
        }
        @media(max-width:500px){
        p{
            font-size: 1.2rem;  
        }
        }
       
        
    }

    
}


.section6{
    @media(min-width:1360px){
        div{
            justify-content: center;
          
        }
        
     }
    width: 100%;
    background-color: var(--background);
    position: relative;
    padding: 6rem 0;
    
    div{
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
    background-color: var(--background);
    margin-bottom: 3rem;
  
        header{
            margin-top: 3rem;
            position: relative;
            width: 40vw;
            z-index: 2;
            &::before{
                content: '';
                position: absolute;
                width: 40vw;
                height: 100%;
                background:  #295381;
                bottom: 1rem;
                right: 1rem;
                z-index: 1;
            }


            img{
                position: relative;
                width: 42vw;
                z-index: 2;
            }
        
        }
        main{
            width:45vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: justify;
             h2{
                color: #295381;
                font-size: 4rem;
                padding-bottom: 2rem;
                text-align: end;
            }
            header{
                display: none;
            }
            

        }
    
       
    }
    p{
        width: 90%;
        color:#696464 ;
        font-size: 1.5rem;
        margin: 0 auto;
        &:last-child{
            margin-top: 1rem;
        }
    }
     @media(max-width:800px){
        div{
            flex-direction: column;
            justify-content: center;
            align-items:center;
            header{
                display: none;
                margin-top: 0;
            }
            main{
            text-align: center;
            width:100%;
            header{
            display: block;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 4rem;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
              
            }


            img{
                position: relative;
                width: 100%;
                z-index: 2;
               
            }
        }
            h2{
                width:100%;
                text-align: center;
                font-size: 3rem;
                padding-bottom: 5rem;
            }
            }
        }
        p{
            text-align: start;
        }
     }
     @media(max-width:400px){
        div{
            
            main{
            text-align: center;
            width:100%;
            header{
            display: block;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 4rem;
                &::before{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                
                }


                img{
                    position: relative;
                    width: 100%;
                    z-index: 2;
                
                }
            }
            h2{
                width:100%;
              
                font-size: 2rem;
                padding-bottom: 2rem;
            }
            p{
                color:#696464 ;
                font-size: 1rem;
              
            }
        }
        }
        
     }

    
}




`