import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: var(--orange300);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0rem 11%;
  height: 50px;
  border-radius: 2rem;
  margin-bottom: 30px;
  position: relative;
  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 36%;
    &:first-child {
      width: 40px;
      height: 40px;
      padding:7px;
      margin-right:7.5%;
      position: relative;
      background-color: #f1f1f1;
      position: absolute;
      left: 0;
    }
    @media(max-width:1000px){
      margin-right: 34%;

      &:first-child {
    
      margin-right:5%;
   
    }
    }
    

    &:last-child {
      margin-right: 0;
    }
  }
`;
