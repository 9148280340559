import styled from "styled-components";

export const Container = styled.div`
width: 100%;
background-color: var(--shape);
color: var(--shape);
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
.section1{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    overflow: hidden;
    &::before{
                content: '';
                position: absolute;
                width:  100%;
                height: 100%;
                background-color: rgba(0,0,0,0.3);
                top: 0rem;
                left: 0rem;
                z-index: 1;
        }
    img{
        width: 100%;

        min-width: 1300px;
        position: absolute;
        left: 0;
        top: -15rem;
        z-index: 0;
        
    }

    div{
        position: relative;
        z-index: 4;
        text-align: center;
        font-size: 1.3rem;

        nav{
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            a{
                color: var(--shape);
                text-decoration: none;
            }
            p{
                margin:0 1rem
            }
        }
    }
    
}
.section2{
 padding: 5rem;
width: 100%;
background-color: var(--shape);
color: var(--shape);
display: flex;
align-items: center;
justify-content: space-between;

h3{
  color: #000;
  font-weight: normal;
}

fieldset{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    margin-top: 1rem;
    input{
    background: #FBFBFB;
    border: 1px solid #F0EFEF;
    border-radius: 3px;
    width: 49%;
    padding: .5rem;
    }

    
}
input, textarea{
    background: #FBFBFB;
    border: 1px solid #F0EFEF;
    border-radius: 3px;
    width: 49%;
    padding: .5rem;
}
textarea{
    margin-top: 2rem;
    width: 100%;
    height:250px ;
}
.div{
    width:40%;
    display:flex;
    flex-direction: column;
    min-height: 405px;
    color: #000;
    span{
        display: flex;
        align-items: start;
        margin: .75rem 0;

        strong{
            width:5rem;
            margin-right:5rem;
            
        }
    }
}
@media(max-width:750px){
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .div{
        width: 100%;
        min-height: 0px;
        margin-bottom: 3rem;
    }
}
@media(max-width:500px){
    fieldset{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    input{
        width: 100%;
        margin-bottom: 1.3rem;
        &:last-child{
            margin-bottom: 0rem;
        }
    }
    }
}
}







`